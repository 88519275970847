<template>
  <div class="">
    <div class="md-layout bg-white" >
      <div class="md-layout-item md-size-100 review-card">
        <Content textColor="text-victoria" labelClass="text-gray-900 " class="my-3 text-uppercase w-full"  text="Basic Information" /> 
        <Content textColor="black" labelClass="text-gray-900" class="my-3 text-uppercase w-full" label="Name of the Director/Owner"  :text="data.user.name" /> 
        <div class="flex">
          <div class="md-layout-item md-size-50 -ml-2">
            <Content textColor="black" labelClass="text-gray-900" class="my-3 text-uppercase w-full" label="Email Address"  :text="data.user.email" /> 
          </div>
          <div class="md-layout-item md-size-50">
            <Content textColor="black" labelClass="text-gray-900" class="my-3 text-uppercase w-full" label="Contact Number"  :text="data.user.user_profile.contact_number" /> 
          </div>
        </div>
      </div>

      <div class="md-layout-item md-size-100 review-card mt-2">
        <Content textColor="text-victoria" labelClass="text-gray-900 " class="my-3 text-uppercase w-full"  text="Address" /> 
        <Content textColor="black" labelClass="text-gray-900" class="my-3 text-uppercase w-full" label="Address"  :text="data.user.user_profile.address" /> 
        <div class="flex">
          <div class="md-layout-item md-size-50 -ml-2">
            <Content textColor="black" labelClass="text-gray-900" class="my-3 text-uppercase w-full" label="Country" :text="data.user.user_profile.country" /> 
          </div>
          <div class="md-layout-item md-size-50">
            <Content textColor="black" labelClass="text-gray-900" class="my-3 text-uppercase w-full" label="State"  :text="data.user.user_profile.state"/> 
          </div>
        </div>
        <div class="flex">
          <div class="md-layout-item md-size-50 -ml-2">
            <Content textColor="black" labelClass="text-gray-900" class="my-3 text-uppercase w-full" label="City"  :text="data.user.user_profile.city" /> 
          </div>
          <div class="md-layout-item md-size-50">
            <Content textColor="black" labelClass="text-gray-900" class="my-3 text-uppercase w-full" label="Postal Code"  :text="data.user.user_profile.postal_code" /> 
          </div>
        </div>
      </div>

      <div class="md-layout-item md-size-100 review-card mt-2">
        <Content textColor="text-victoria" labelClass="text-gray-900 " class="my-3 text-uppercase w-full"  text="Business Info" /> 
        <Content textColor="black" labelClass="text-gray-900" class="my-3 text-uppercase w-full" label="Business Name"  :text="data.organization.business_name" /> 
        <Content textColor="black" labelClass="text-gray-900" class="my-3 text-uppercase w-full" label="ABN/ACN Number"  :text="data.organization.business_account_number" /> 
        <div class="flex">
          <div class="md-layout-item md-size-50 -ml-2">
            <Content textColor="black" labelClass="text-gray-900" class="my-3 text-uppercase w-full" label="Email Address"  :text="data.organization.business_email" /> 
          </div>
          <div class="md-layout-item md-size-50">
            <Content textColor="black" labelClass="text-gray-900" class="my-3 text-uppercase w-full" label="Contact Number"   :text="data.organization.business_contact_number" /> 
          </div>
        </div>
        <Content textColor="black" labelClass="text-gray-900" class="my-3 text-uppercase w-full" label="Address"  :text="data.organization.business_full_address" /> 
        <div class="flex">
          <div class="md-layout-item md-size-50 -ml-2">
            <Content textColor="black" labelClass="text-gray-900" class="my-3 text-uppercase w-full" label="Country" :text="data.organization.country" /> 
          </div>
          <div class="md-layout-item md-size-50">
            <Content textColor="black" labelClass="text-gray-900" class="my-3 text-uppercase w-full" label="State" :text="data.organization.business_state" /> 
          </div>
        </div>
        <div class="flex">
          <div class="md-layout-item md-size-50 -ml-2">
            <Content textColor="black" labelClass="text-gray-900" class="my-3 text-uppercase w-full" label="City"  :text="data.organization.business_city"  /> 
          </div>
          <div class="md-layout-item md-size-50">
            <Content textColor="black" labelClass="text-gray-900" class="my-3 text-uppercase w-full" label="Postal Code"  :text="data.organization.postal_code" /> 
          </div>
        </div>
      </div>

      <div class="md-layout-item md-size-100 review-card mt-2">
          <div class="featur-list">
            <ul>
              <li class="text-victoria text-xl text-uppercase font-bold">Package Information <span class="right-position" >READY MADE ({{data.organization.payment_type}})</span></li> 
              <li class="font-bold"> No of Questions <span class="right-position" >{{ packageDetail('pte_practice')}}</span></li> 
              <li  class="font-bold"> No of Practice Test <span class="right-position">{{ packageDetail('practice_test')}}</span></li> 
              <li class="font-bold"> No of Mock Test <span class="right-position">{{ packageDetail('mock_test')}}</span></li> 
              <li   class="font-bold">No of Quiz <span class="right-position">{{ packageDetail('quiz')}}</span></li> 
              <li class="font-bold">Materials <span class="right-position">{{ packageDetail('materials')}}</span></li> 
              <li  class="font-bold"> Live Class <span class="right-position">{{ packageDetail('live_classes')}}</span></li> 
              <li  class="font-bold">Webinars <span class="right-position">{{ packageDetail('webinars')}}</span></li> 
              <li  class="font-bold"> One-One Appointment <span class="right-position">{{ packageDetail('one_to_one_appointment')}}</span></li>
            </ul>
          </div>
      </div>

      <div class="md-layout-item md-size-100 review-card mt-2">
        <Content textColor="text-victoria" labelClass="text-gray-900 " class="my-3 text-uppercase w-full"  text="Portal Access" /> 
        <Content textColor="black" labelClass="text-gray-900" class="my-3 text-uppercase w-full" label="Do you need landing page"  :text="data.organization.want_landing_page_value" /> 
        <Content textColor="black" labelClass="text-gray-900" class="my-3 text-uppercase w-full" label="Portal Sub-Domain"  :text="data.organization.landing_page_url" /> 
      </div>

      <div class="md-layout-item md-size-100 review-card mt-2">
        <Content textColor="text-victoria" labelClass="text-gray-900 " class="my-3 text-uppercase w-full"  text="Portal Admin" /> 
        <Content textColor="black" labelClass="text-gray-900" class="my-3 text-uppercase w-full" label="Name of the admin portal"  :text="data.portalAdmin.name" /> 
        <!-- <Content textColor="black" labelClass="text-gray-900" class="my-3 text-uppercase w-full" label="Designation"  text="Sub-Amin" />  -->
        <div class="flex">
          <div class="md-layout-item md-size-50 -ml-2">
            <Content textColor="black" labelClass="text-gray-900" class="my-3 text-uppercase w-full" label="Email Address"  :text="data.portalAdmin.email" /> 
          </div>
          <div class="md-layout-item md-size-50">
            <Content textColor="black" labelClass="text-gray-900" class="my-3 text-uppercase w-full" label="Contact Number"  :text="data.portalAdmin.contact_number" /> 
          </div>
        </div>
        <Content textColor="black" labelClass="text-gray-900" class="my-3 text-uppercase w-full" label="Address"  :text="data.portalAdmin.user_profile.address" /> 
        <div class="flex">
          <div class="md-layout-item md-size-50 -ml-2">
            <Content textColor="black" labelClass="text-gray-900" class="my-3 text-uppercase w-full" label="Country"  :text="data.portalAdmin.user_profile.country"  /> 
          </div>
          <div class="md-layout-item md-size-50">
            <Content textColor="black" labelClass="text-gray-900" class="my-3 text-uppercase w-full" label="State"  :text="data.portalAdmin.user_profile.state" /> 
          </div>
        </div>
        <div class="flex">
          <div class="md-layout-item md-size-50 -ml-2">
            <Content textColor="black" labelClass="text-gray-900" class="my-3 text-uppercase w-full" label="City"  :text="data.portalAdmin.user_profile.city" /> 
          </div>
          <div class="md-layout-item md-size-50">
            <Content textColor="black" labelClass="text-gray-900" class="my-3 text-uppercase w-full" label="Postal Code"  :text="data.portalAdmin.user_profile.postal_code" /> 
          </div>
        </div>
      </div>
    </div>

    <div class="flex justify-end mt-4">
      <!-- <md-button class="ml-auto text-uppercase" @click="clearAll">Clear all</md-button> -->
      <Button
          :loading="loading"
          class="bg-victoria rounded text-white mr-0"
          label="Make Payment"
          type="button"
          @click="submit"
      >
      </Button>
    </div>
  </div>
</template>

<script>

import {
  Button,
} from "@/components"
import Content from "@/components/atom/Content";
import {mapActions} from 'vuex';
export default {
  name: "BasicInformation",

  components: {
    Button,
    Content
  },

  data() {
    return {
      loading: false,
      data: '',
      packageInfo: '',

    }
  },

  methods: {
    ...mapActions({
      selectedPackage: "registration/actGetPackageById",
    }),
    packageDetail(key) {
      return  this.packageInfo&&this.packageInfo.package_detail&&this.packageInfo.package_detail[key]&&this.packageInfo.package_detail[key]!="undefined"?this.packageInfo.package_detail[key]:0;
    },
 
    submit() {

      this.$emit("menu", this.$route.name);
      this.$router.push({ name: 'coachingCenterPayment'});
    },

    clearAll(){
      this.$emit("clear-all", "");
    }
  },

  created() {
    let existData = this.getLocal('coaching-center-onboard');
    if (existData ) this.data = existData;
    let packageId;
  
    if (existData && existData.organization && existData.organization.packages && existData.organization.packages[0]) packageId = existData.organization.packages[0];
    if(packageId){
        this.selectedPackage(packageId).then(({items}) => this.packageInfo = items);
    }
    
  },
}
</script>
