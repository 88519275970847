<template>

<div class="md-layout-item md-size-30 mt-5 mr-5" style="padding-right:0px; padding-left:0px" >
  <div class="package-card" @click="selectPackage">
    <div class="bg-primary rounded">
     
        <h2 class=" text-base  pt-5 px-2 text-center text-gray-600">{{ packageList.name || 'Name is empty' }} </h2>
        <div class="my-6 text-center">
            <span class="discount-price text-gray-600">$ {{ payment_type=="WEEKLY"? weeklyOldPrice(detail): monthlyOldPrice(detail)}} </span>
            <h2 class="my-4 text-4xl font-bold text-white">$ {{ payment_type=="WEEKLY"? weeklyNewPrice(detail): monthlyNewPrice(detail) }} </h2>
        </div>
    </div>

    <div class="featur-list">
      <ul>
        <li class="font-bold"><span class="text-primary text-lg font-bold"> &check; </span> No of PTE Practice <span class="right-position" >{{ detail?detail.pte_practice:"" }}</span></li> 
        <li  class="font-bold"><span class="text-primary text-lg font-bold"> &check; </span> No of Practice Test <span class="right-position">{{ detail?detail.practice_test:"" }}</span></li> 
        <li class="font-bold"><span class="text-primary text-lg font-bold"> &check; </span> No of Mock Test <span class="right-position">{{ detail?detail.mock_test:"" }}</span></li> 
        <li   class="font-bold"><span class="text-primary text-lg font-bold"> &check; </span> No of Quiz <span class="right-position">{{ detail?detail.quiz:"" }}</span></li> 
        <li class="font-bold"><span class="text-primary text-lg font-bold"> &check; </span> Materials <span class="right-position">{{ detail?detail.materials:"" }}</span></li> 
        <li  class="font-bold"><span class="text-primary text-lg font-bold"> &check; </span> Live Class <span class="right-position">{{detail? detail.live_classes:"" }}</span></li> 
        <li  class="font-bold"><span class="text-primary text-lg font-bold"> &check; </span> Webinars <span class="right-position">{{ detail?detail.webinars:"" }}</span></li> 
        <li  class="font-bold"><span class="text-primary text-lg font-bold"> &check; </span> One-One Appointment <span class="right-position">{{ detail?detail.one_to_one_appointment:"" }}</span></li>
      </ul>
    </div>
    <div class="mt-5 text-center">
      
      <Button
          v-if="!isActive" 
          class="rounded w-full ml-0 font-bold bg-white text-victoria"          
          style="border: 1px solid;"
          label="Select"
          type="button"
      >
      </Button>
      <Button
          v-if="isActive" 
          class="rounded w-full ml-0 font-bold bg-victoria text-white"          
          style="border: 1px solid;"
          label="Select"
          type="button"
          :checked=true
      >
      </Button>
    </div>

    <div v-if="isPopular" class="branding rounded text-white text-uppercase font-bold text-center p-1">
      Popular
    </div>
  </div>
</div>
</template>

<script>

import {
  Button
} from "@/components"
export default {

  components: {
    Button,
  },
  props: {
    /*isActive: {
      type: Boolean,
      default: () => false
    },*/
    payment_type: {
      type: String
    },
    packageList: {
      type: Object
    },
    selectedPackage: {
      type: Object
    },
    isPopular: {
      type: Boolean,
      default: () => false
    },
  },
  computed: {
    
    isActive() {
      if (typeof this.selectedPackage !== "undefined" && typeof this.packageList !== "undefined") {
        if (this.selectedPackage.id == this.packageList.id) {
          return true;
        }
      }
      return false;
    },
    detail() {
      return this.packageList.package_detail;
    }
  },
  methods: {
    selectPackage() {
      this.$emit('select-package', this.packageList)
    },
    weeklyOldPrice(detail){
      return detail?detail.weekly_old_price:'';
    },
    weeklyNewPrice(detail){
      return detail?detail.weekly_new_price:'';
    },
    monthlyOldPrice(detail){
      return detail?detail.monthly_old_price:'';
    },
    monthlyNewPrice(detail){
      return detail?detail.monthly_new_price:'';
    }
  }
}
</script>