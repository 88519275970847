<template>
  <div>
    <div>
        <label class="mb-1 ml-1 pr-4 text-uppercase font-bold block text-base">How You Want TO Pay US </label>
    </div>
    <div class="md-layout mt-4">
        <div class="md-layout-item md-size-50">
            <InputFieldComponent
                label="Card Holder Name"
                label-class="text-sm"
            />
        </div>
        <div class="md-layout-item md-size-50">
          <label class="text-uppercase font-bold block">CARD NUMBER</label>

          <div id="card-number"></div>
        </div>
        <div class="md-layout-item md-size-50 pt-5">
           <label class="text-uppercase font-bold block">EXPIRATION DATE</label>
    
            <div id="card-expiry"></div>
        </div>
        <div class="md-layout-item md-size-50 pt-5">
          <label class="text-uppercase font-bold block">CVC/CVV</label>
            <div id="card-cvc"></div>
        </div>
        <div class="md-layout-item md-size-100 pt-5">
          <div id="card-error">{{error}}</div>
        </div>
        <div v-if="payButton" class="md-layout-item md-size-100 pt-5 text-center">
          <button id="custom-button" @click="paymentSubmit">Pay Now</button>
        </div>
    </div>
  
    
  </div>
</template>

<script>


import Vue from 'vue';
import api from './api';
import { InputFieldComponent } from '@/components';
// Stripe Plugin
import { StripePlugin } from '@vue-stripe/vue-stripe';
const options = {
  pk: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
  // stripeAccount: "ca_JdhMiKAE2OMkfoLCBunVpUTb5ZLwzFfC",
  // stripeAccount: process.env.VUE_APP_STRIPE_ACCOUNT,
  apiVersion: process.env.VUE_APP_STRIPE_API_VERSION,
  locale: process.env.VUE_APP_STRIPE_LOCALE,
};

Vue.use(StripePlugin, options);

export default {
    props: {
      amount: {
        type: Number,
        default: () => 0
      },
      payButton: {
        type: Boolean,
        default: () => true
      },
    },
    components: {
        InputFieldComponent
    },
    data () {
      return {
        error: null,
        token: null,
        cardNumber: null,
        cardExpiry: null,
        cardCvc: null,
      };
    },
    computed: {
      stripeElements () {
        return this.$stripe.elements();
      },
    },
    mounted () {
     
      // Style Object documentation here: https://stripe.com/docs/js/appendix/style
      const style = {
        base: {
          color: 'black',
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '14px',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a',
        },
      };
      this.cardNumber = this.stripeElements.create('cardNumber', { style });
      this.cardNumber.mount('#card-number');
      this.cardExpiry = this.stripeElements.create('cardExpiry', { style });
      this.cardExpiry.mount('#card-expiry');
      this.cardCvc = this.stripeElements.create('cardCvc', { style });
      this.cardCvc.mount('#card-cvc');
    },
    beforeDestroy () {
      this.cardNumber.destroy();
      this.cardExpiry.destroy();
      this.cardCvc.destroy();
    },
    methods: {
      async paymentSubmit () {
         
        this.error = null;

        const stripe = this.$stripe;
       
        try {

          const billingDetails = {
             name: 'Apical',
             email: 'admin@blubird.com',
             address: {    
                line1: '',
                line2: '',
             }
          }

          //create new payment method based on card and form information
          const payload = await stripe.createPaymentMethod({
              type: "card",
              card: this.cardNumber,
              billing_details: billingDetails
          });

          //handle errors, otherwise set the new payment method in state
          if (payload.error) {
              this.error = payload.error.message;
              return;
          } 

          const intentData  = await api('post', `api/v1/payment-intent`, {amount: this.amount})
              .then( ({response}) => {
                  return response
              })
              .catch(error => console.log(error))

          const result = await stripe.confirmCardPayment(intentData.client_secret, {
              payment_method: payload.paymentMethod.id,
          })

          //handle errors again
          if (result.error) {
              this.$emit('getObject', result.error);
              return
          }

           // The payment has been processed! send a confirmation to the server
          if (result.paymentIntent.status === "succeeded") {
              this.$emit('getObject', result.paymentIntent);

          }

        } catch (error) {
          this.$emit('getObject', error);
        }
      }
    }
  };
</script>
<style scoped>
#custom-button {
  height: 30px;
  outline: 1px solid grey;
  background-color: green;
  padding: 5px;
  color: white;
  
}

#card-error {
  color: red;
}
.StripeElement {
  border: 1px solid #0000003b;
  padding: 10px;
  border-radius: 5px;
  margin-top: 5px;
}
</style>