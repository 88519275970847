<template>
  <div style="">
    <div class="md-layout">
      <div class="md-layout-item md-size-100 mt-5">
          <div class="package-card text-white pl-3" style="background-color: #F7F4FC; color:black !important;">
            <label class="text-uppercase font-bold block mt-3 ml-1 text-victoria">Package Information</label>
            <div class="featur-list">
              <ul>

                <li class="font-bold"> No of Question <span class="right-position" >{{ packageDetail('no_of_question')}}</span></li> 
                <li class="font-bold"> No of Pte Practice <span class="right-position" >{{ packageDetail('pte_practice')}}</span></li> 
                <li class="font-bold"> No of Practice Test <span class="right-position">{{ packageDetail('practice_test') }}</span></li> 
                <li class="font-bold"> No of Mock Test <span class="right-position">{{ packageDetail('mock_test') }}</span></li> 
                <li class="font-bold">No of Quiz <span class="right-position">{{ packageDetail('quiz') }}</span></li> 
                <li class="font-bold">Materials <span class="right-position">{{ packageDetail('materials') }}</span></li> 
                <li class="font-bold"> Live Class <span class="right-position">{{ packageDetail('live_classes') }}</span></li> 
                <li class="font-bold">Webinars <span class="right-position">{{ packageDetail('webinars') }}</span></li> 
                <li class="font-bold"> One-One Appointment <span class="right-position">{{ packageDetail('one_to_one_appointment') }}</span></li>
              </ul>
            </div>
          </div>
          <div class="package-card text-white pl-3 pt-10" style="background-color: #FFFFF; color:black !important; border:none !important">
            <div class="package-pricing">
              <ul>
                <li> <strong> Package Price ({{payment_type}})</strong> <br> Ready Made Package <span class="right-position">${{ packagePrice() }}</span></li> 
             
                <li> Portal Fee <span class="right-position">{{portalFee}}</span></li> 
                <li> Promocode <span class="right-position"><InputFieldComponent type="text" class="w-20 ml-5" inputClass='p-1 text-right' @input="checkPromoCode" v-model="promocode" /></span></li> 
                <li> Discount Price <span class="right-position">{{discount}}</span></li> 
                <li> Total Price <span class="text-uppercase font-bold block mt-3 price-summary right-position" >Price ${{totalPrice()}}</span></li> 
              </ul>
            </div>
            
          </div>

      </div>
      <div class="md-layout-item md-size-100 mt-5">
        <StripeCard ref="stripeCard" @getObject="getStripeResponse" :payButton=false :amount="totalPrice()" />
      </div>
    
    </div>

    <div class="flex justify-end mt-4">
      <md-button class="ml-auto text-uppercase" @click="clearAll">Clear all</md-button>
      <Button
          :loading="loading"
          class="bg-victoria rounded text-white mr-0"
          label="Pay & Save"
          type="button"
          @click="submit"
      >
      </Button>
    </div>
  </div>
</template>

<script>

import {
  Button,
  InputFieldComponent
} from "@/components"
import StripeCard from "@/store/StripeCard.vue";
import {mapActions, mapMutations} from 'vuex';
export default {
  name: "Payment",

  components: {
    Button,
    InputFieldComponent,
    StripeCard
    // StripeElementCard
  },
  data() {
    return {
      user_id: '',
      admin_id: '',
      organization_id:'',
      users: [],
      loading: false,
      packageInfo: '',
      promo_code_id: '',
      payment_type:'',
      packageId: '',
      portalFee: 10,
      promocode: '',
      discount: 0,
      invoiceInstalment: {},
      invoiceInstalmentResponse: {},
      transaction:{},
      form: {
        
      }
    }
  },

  methods: {
    ...mapActions({
      selectedPackage: "registration/actGetPackageById",
      actInvoiceInstalment: "registration/actInvoiceInstalment",
      actTransaction: "registration/actTransaction",
      actGetPromoCode: "registration/actGetPromoCode",
      actUserRegister: "user/actUserRegister",
      actOrganizationSave: "registration/actOrganizationSave"
    }),
    ...mapMutations({
      setShowSnackbar: "setShowSnackbar"
    }),
    packagePrice(){
       return this.payment_type=="WEEKLY"? this.packageDetail('weekly_new_price'): this.packageDetail('monthly_new_price');
    },
    totalPrice(){
      return parseFloat(this.packagePrice()) + parseFloat(this.portalFee) - parseFloat(this.discount);
    },
    packageDetail(key) {
      return  this.packageInfo&&this.packageInfo.package_detail&&this.packageInfo.package_detail[key]&&this.packageInfo.package_detail[key]!="undefined"?this.packageInfo.package_detail[key]:0;
    },
    currentDate() {
      const current = new Date();
      const date = `${current.getFullYear()}-${current.getMonth()+1}-${current.getDate()}`;
      return date;
    },

    checkPromoCode(code){
        this.actGetPromoCode(code).then(({items}) => {

          let result = items;
          this.promo_code_id = result.id;
          if(result.discount_type=='PERCENTAGE'){
            if(this.packagePrice() > 0 && result.discount > 0){
              this.discount = (this.packagePrice()*result.discount)/100;
              this.discount = result.discount;
            }
          }else{
              this.discount = result.discount;
          }
          
      }).catch(error => {
          console.log(error);
          this.discount = 0
      }).finally(() => this.loading = false)
    },
    saveInvoiceInstalment(){
      let existData = this.getLocal('coaching-center-onboard');
      let user_id;
      if(existData && existData.organization && existData.organization.users && existData.organization.users.lenght > 0){
        user_id = existData.organization.users[0];
      }
      this.invoiceInstalment = {
          "organization_id": existData.organization.organization_id,
          "package_id": existData.organization.packages[0]?existData.organization.packages[0]:'',
          "user_id": user_id,
          "promo_code_id": this.promo_code_id?this.promo_code_id:'',
          "payment_type": existData.organization.payment_type,
          "payment_for": "ORGANIZATION_ONBOARDING",
          "number_of_instalments":1,
          "instalment_tracker":[
              {
                  "payable_amount": this.totalPrice(),
                  "due_date": this.currentDate(),
                  "status": true
              }
          ]
      }
      this.actInvoiceInstalment(this.invoiceInstalment).then(({items}) => {
          this.invoiceInstalmentResponse = items;
        // localStorage.setItem("coaching-center-success", true);
        // this.remove("coaching-center-onboard");
        // this.$emit("menu", this.$route.name);
        // this.$router.push({
        //   name: 'registrationSuccess',
        //   params: this.formDetails
        // });
      }).finally(() => this.loading = false)
    },

    saveTransaction(response){

        this.transaction = {
          "invoice_id": this.invoiceInstalmentResponse.invoice_details.invoice_id,
          "payment_date": this.currentDate(),
          "total": response.amount/100,
          "transaction_source": "STRIPE",
          "transaction_details": response,
          "status": "SUCCESSFULL"
        }

      this.actTransaction(this.transaction).then(() => {
      }).finally(() => this.loading = false)
        
    },

    getStripeResponse(response){
        
        if(response.status=="succeeded"){
            this.saveTransaction(response);
            this.setShowSnackbar('Payment succeeded');
            // this.$emit("menu", this.$route.name);
            // this.$router.push({ name: 'coachingCenterPortalAccess'});
        }else{
            this.setShowSnackbar('Payment not succeeded');
        }
    },

    createOrganization(){

      let formData = this.getLocal('coaching-center-onboard');

      this.actOrganizationSave(formData.organization)
      .then(({ organization_id, message }) => {
     
        if(formData.organization){
          formData.organization.organization_id = organization_id;
        }else{
          formData.organization = {
            ...formData.organization,
            organization_id : organization_id
          }
        }
        
        formData.organization.users = this.users;
        formData.organization.organization_id = this.organization_id;
        this.setLocal('coaching-center-onboard', formData);

        this.saveInvoiceInstalment();
        this.$refs.stripeCard.paymentSubmit();

        
        this.setShowSnackbar(message);

        // this.$emit("menu", this.$route.name);
        this.remove('coaching-center-onboard');
        this.remove('coaching-center-onboard-menu');
        this.$router.push({ name: 'master.coachingCenter'});
      })
       .catch((error) => {
          let errors = error.response.data.details;
          if (errors.business_website) errors = errors.business_website[0];
          if (errors.business_account_number) errors = errors.business_account_number[0];
          if (errors.business_contact_number) errors = errors.business_contact_number[0];
          if (errors.business_email) errors = errors.business_email[0];
          if (errors.business_name) errors = errors.business_name[0];
          if(errors != null){

            this.setShowSnackbar(errors);
        }
      }).finally(() => this.loading = false)
    },

    createOrganizationOwner(user){

      let formData = this.getLocal('coaching-center-onboard');
      let userData = formData[user];

      this.actUserRegister(userData)
      .then(({ user_id, message }) => {
        if(formData.organization.users){
          this.users = [...formData.organization.users];
          this.users.push(user_id)
          formData.organization.users = this.users;
        }else{
          this.users.push(user_id)
          formData.organization = {
            ...formData.organization,
            users : this.users
          };
        }
        this.setLocal('coaching-center-onboard', formData);
        this.setShowSnackbar(message);
         
      })
       .catch((error) => {
        let errors = error.response.data.details;
        if (errors.email) errors = errors.email[0];
        if (errors.name) errors = errors.name[0];
        if(errors != null){
          this.setShowSnackbar(errors);
          return false;
        }
      }).finally(() => this.loading = false)

    },
    submit() {

      this.$v.$touch();
      if(this.$v.$invalid) return;

      try{
        let formData = this.getLocal('coaching-center-onboard');
        if(formData.organization.users && formData.organization.users.lenght < 0){

          if(!formData.portalAdminSame){
              this.createOrganizationOwner('portalAdmin');
          }
          this.createOrganizationOwner('user');
        }else{
          this.createOrganizationOwner('user');
        }

       setTimeout(function () {     
         
        if(!formData.organization.organization_id){
          this.createOrganization();
        }
        

        }.bind(this), 3000);

      } catch (error) {
        console.error(error);
        // expected output: ReferenceError: nonExistentFunction is not defined
        // Note - error messages will vary depending on browser
      }
    },
    clearAll(){
      this.$emit("clear-all", "");
    }
  },

  created() {
    
    let existData = this.getLocal('coaching-center-onboard');
    if (existData && existData.organization) this.payment_type = existData.organization.payment_type;
    if (existData && existData.organization && existData.organization.packages[0]) this.packageId = existData.organization.packages[0];

    this.selectedPackage(this.packageId).then(({items}) => this.packageInfo = items);
  },

  validations: {
    form: { 
    }
  }
}
</script>
