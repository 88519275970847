<template>
  <div style="">
    <div class="md-layout">
      <!-- <div class="md-layout-item md-size-100 my-6">
        <label class="text-uppercase font-bold text-xl block mt-6">Package Type *</label>
        <RadioButtonComponent         
         label-class="font-bold"
         :label-outline="false"
         :block="false"
         :items="[{id:'READY_MADE', label: 'Ready Made'}, {id:'CUSTOME', label: 'Custome'}]"
         v-model.trim="$v.form.package_type.$model"
         :message="!$v.form.package_type.required && $v.form.package_type.$dirty ? 'Field is required' : null"
                
        />
      </div> -->

      <div class="md-layout  md-alignment-center md-layout-item md-size-100 flex">
          <label class="text-uppercase font-bold block" :class="[WeeklyTextColor]" >Weekly</label>
          <md-switch
           v-model="radio"
           class="px-2 m-0"
           @change="onChangePaymentType"
          ></md-switch>
          <label class="text-uppercase font-bold block" :class="[MonthlyTextColor]">Monthly</label>
      </div>
      <div class="md-layout md-gutter">
        <div v-if="form.package_type=='CUSTOME'" class="md-layout-item md-size-100 my-6 flex">
          <div class="md-layout-item md-size-50">
            <label class="text-uppercase font-bold block mt-3">Number of Questions *</label>
            <div class="flex my-3">
                <InputFieldComponent inputClass='py-3'  type="range" class="w-full" min="0"  max="100" step="1" v-model="ptePracticeSlider" />
                <InputFieldComponent  type="text" v-model="basePrice.pte_practice.quantity" @input="updateSlider"  class="w-20 ml-5" inputClass='bg-custome-package-input p-3' />
            </div> 
            <label class="text-uppercase font-bold block mt-3">Number of Practice Test *</label>
            <div class="flex my-3">
                <InputFieldComponent inputClass='py-3'  type="range" class="w-full" min="0"  max="100" step="1" v-model="practiceTestSlider" />
                <InputFieldComponent  type="text" v-model="basePrice.practice_test.quantity" @input="updateSlider"  class="w-20 ml-5" inputClass='bg-custome-package-input p-3' />
            </div> 
            <label class="text-uppercase font-bold block mt-3">Number of Mock Test *</label>
            <div class="flex my-3">
                <InputFieldComponent inputClass='py-3'  type="range" class="w-full" min="0"  max="100" step="1" v-model="mockTestSlider" />
                <InputFieldComponent  type="text" v-model="basePrice.mock_test.quantity" @input="updateSlider"  class="w-20 ml-5" inputClass='bg-custome-package-input p-3' />
            </div> 
            <label class="text-uppercase font-bold block mt-3">Number of Quiz *</label>
            <div class="flex my-3">
                <InputFieldComponent inputClass='py-3'  type="range" class="w-full" min="0"  max="100" step="1" v-model="quizSlider" />
                <InputFieldComponent  type="text" v-model="basePrice.quiz.quantity" @input="updateSlider"  class="w-20 ml-5" inputClass='bg-custome-package-input p-3' />
            </div> 
            <label class="text-uppercase font-bold block mt-3">Number of Materials *</label>
            <div class="flex my-3">
                <InputFieldComponent inputClass='py-3'  type="range" class="w-full" min="0"  max="100" step="1" v-model="materialsSlider" />
                <InputFieldComponent  type="text" v-model="basePrice.materials.quantity" @input="updateSlider"  class="w-20 ml-5" inputClass='bg-custome-package-input p-3' />
            </div> 
            <label class="text-uppercase font-bold block mt-3">Number of Webinars *</label>
            <div class="flex my-3">
                <InputFieldComponent inputClass='py-3'  type="range" class="w-full" min="0"  max="100" step="1" v-model="webinarsSlider" />
                <InputFieldComponent  type="text" v-model="basePrice.webinars.quantity" @input="updateSlider"  class="w-20 ml-5" inputClass='bg-custome-package-input p-3' />
            </div> 
            <label class="text-uppercase font-bold block mt-3">Number of Online Class *</label>
            <div class="flex my-3">
                <InputFieldComponent inputClass='py-3'  type="range" class="w-full" min="0"  max="100" step="1" v-model="onlineClassSlider" />
                <InputFieldComponent  type="text" v-model="basePrice.online_class.quantity" @input="updateSlider"  class="w-20 ml-5" inputClass='bg-custome-package-input p-3' />
            </div> 
            <label class="text-uppercase font-bold block mt-3">Number of One-to-One Appointment *</label>
            <div class="flex my-3">
                <InputFieldComponent inputClass='py-3'  type="range" class="w-full" min="0"  max="100" step="1" v-model="oneToOoneAppointmentSlider" />
                <InputFieldComponent  type="text" v-model="basePrice.one_to_one_appointment.quantity" @input="updateSlider"  class="w-20 ml-5" inputClass='bg-custome-package-input p-3' />
            </div> 
          </div>
          <div class="md-layout-item md-size-50">
            <div class="package-card bg-victoria text-white pl-3">
              <label class="text-uppercase font-bold block mt-3 ml-1">Package Information</label>
              <div class="featur-list">
                <ul>
                  
                  <li style="background-color: #514b99;" class="font-bold"> No of Questions <span class="right-position" >{{basePrice.pte_practice.price}}</span></li> 
                  <li style="background-color: #514b99;" class="font-bold"> No of Practice Test <span class="right-position">{{basePrice.practice_test.price}}</span></li> 
                  <li style="background-color: #514b99;" class="font-bold"> No of Mock Test <span class="right-position">{{basePrice.mock_test.price}}</span></li> 
                  <li style="background-color: #514b99;" class="font-bold">No of Quiz <span class="right-position">{{basePrice.quiz.price}}</span></li> 
                  <li style="background-color: #514b99;" class="font-bold">Materials <span class="right-position">{{basePrice.materials.price}}</span></li> 
                  <li style="background-color: #514b99;" class="font-bold"> Live Class <span class="right-position">{{basePrice.online_class.price}}</span></li> 
                  <li style="background-color: #514b99;" class="font-bold">Webinars <span class="right-position">{{basePrice.webinars.price}}</span></li> 
                  <li style="background-color: #514b99;" class="font-bold"> One-One Appointment <span class="right-position">{{basePrice.one_to_one_appointment.price}}</span></li>
                </ul>
                  <span class="text-uppercase font-bold block mt-3 price-summary " >Price ${{totalPackagePrice()}}</span>
              </div>
            </div>
          </div>
        </div>

        <div  v-if="form.package_type=='READY_MADE'" class="md-layout md-layout-item md-size-100">
          <PackageCard
              v-for="item in packages"
              :key="item.id"
              :packageList="item"
              :payment_type="form.payment_type"
              :selectedPackage="selectedPackage"
              @select-package="onSelectPackage"
          />
        </div>  
      </div>  
    </div>

    <div class="flex justify-end mt-4">
      <md-button class="ml-auto text-uppercase" @click="clearAll">Clear all</md-button>
      <Button
          :loading="loading"
          class="bg-victoria rounded text-white mr-0"
          label="Next"
          type="button"
          @click="submit"
      >
      </Button>
    </div>
  </div>
</template>

<script>

import {
  Button,
  // RadioButtonComponent,
  InputFieldComponent
} from "@/components"
import { required } from "vuelidate/lib/validators";
import PackageCard from '@/components/molecule/card/PackageCard';
import {mapActions, mapMutations} from 'vuex';
export default {
  name: "BasicInformation",

  components: {
    Button,
    // RadioButtonComponent,
    PackageCard,
    InputFieldComponent
  },

  data() {
    return {
      loading: false,
      radio: false,
      ptePracticeSlider: 0,
      practiceTestSlider: 0,
      mockTestSlider: 0,
      quizSlider: 0,
      materialsSlider: 0,
      webinarsSlider: 0,
      onlineClassSlider: 0,
      oneToOoneAppointmentSlider: 0,
      WeeklyTextColor:'text-victoria',
      MonthlyTextColor: '',
      packages: [],
      packageBasePrice: [],
      selectedPackage: {},
      form: {
        package_type: 'READY_MADE',
        payment_type: 'WEEKLY',
        packages: [],
        want_landing_page: false,
        is_portal_fee_paid: false,
        pte_practice:'',
        practice_test: '',
        mock_test: '',
        quiz: '',
        webinars: '',
        online_class: '',
        one_to_one_appointment: '',
      },
      basePrice: {
        pte_practice : {quantity:0, price:0},
        practice_test : {quantity:0, price:0},
        mock_test :{quantity:0, price:0},
        quiz : {quantity:0, price:0},
        materials : {quantity:0, price:0},
        webinars : {quantity:0, price:0},
        online_class : {quantity:0, price:0},
        one_to_one_appointment : {quantity:0, price:0},
      }
    }
  },
  watch: {

    ptePracticeSlider(range) {
      this.basePrice.pte_practice.quantity = range*10;  
      this.basePrice.pte_practice.price = this.basePrice.pte_practice.quantity*this.packageBasePrice.question_value;  
    },
    practiceTestSlider(range) {
        this.basePrice.practice_test.quantity = range*10;
        this.basePrice.practice_test.price = this.basePrice.practice_test.quantity*this.packageBasePrice.practice_test_value;   
    },
    mockTestSlider(range) {
      this.basePrice.mock_test.quantity = range*10; 
      this.basePrice.mock_test.price = this.basePrice.mock_test.quantity*this.packageBasePrice.mock_test_value;  
    }
    ,
    quizSlider(range) {
        this.basePrice.quiz.quantity = range*10; 
        this.basePrice.quiz.price = this.basePrice.quiz.quantity*this.packageBasePrice.quiz_value;  
      
    }
    ,
    webinarsSlider(range) {
        this.basePrice.webinars.quantity = range*10;  
        this.basePrice.webinars.price = this.basePrice.webinars.quantity*this.packageBasePrice.webinars_value; 

    }
    ,
    materialsSlider(range) {
      this.basePrice.materials.quantity = range*10;  
      this.basePrice.materials.price = this.basePrice.materials.quantity*this.packageBasePrice.material_value; 
    }
    ,
    onlineClassSlider(range) {
      this.basePrice.online_class.quantity = range*10;  
      this.basePrice.online_class.price = this.basePrice.online_class.quantity*this.packageBasePrice.online_class_value; 
    }
    ,
    oneToOoneAppointmentSlider(range) {
      this.basePrice.one_to_one_appointment.quantity = range*10;  
      this.basePrice.one_to_one_appointment.price = this.basePrice.one_to_one_appointment.quantity*this.packageBasePrice.appointment_value; 
    },
  },
  methods: {
    ...mapMutations({
      setShowSnackbar: "setShowSnackbar"
    }),
     ...mapActions({
      actPackages: "registration/actPackages",
      actPackageBasePrice: "registration/actPackageBasePrice",
    
    }),

    totalPackagePrice(){

      return  (
        this.basePrice.webinars.price?this.basePrice.webinars.price:0 + 
        this.basePrice.quiz.price?this.basePrice.quiz.price:0 + 
        this.basePrice.materials.price?this.basePrice.materials.price:0 + 
        this.basePrice.mock_test.price?this.basePrice.mock_test.price:0 + 
        this.basePrice.one_to_one_appointment.price?this.basePrice.one_to_one_appointment.price:0 + 
        this.basePrice.online_class.price?this.basePrice.online_class.price:0 + 
        this.basePrice.practice_test.price?this.basePrice.practice_test.price:0 + 
        this.basePrice.pte_practice.price?this.basePrice.pte_practice.price:0
      );
    },

    updateSlider(key,value){
      this.rangeSlider[key]=value/10;
    },
    onSelectPackage(value) {
      this.selectedPackage = value;
      this.form.packages = [];
      this.form.packages.push(value.id);
    },


    onChangePaymentType(value) {
      if(value){
        this.form.payment_type = "MONTHLY";
        this.MonthlyTextColor = "text-victoria";
        this.WeeklyTextColor = "";
      }else{
        this.form.payment_type = "WEEKLY";
        this.WeeklyTextColor = "text-victoria";
        this.MonthlyTextColor = "";
      }
      return value;
    }, 
    submit() {

      this.$v.$touch();
      if(this.$v.$invalid) return;

      let formData = this.getLocal('coaching-center-onboard');
      formData.organization = {
        ...formData.organization,
        ...this.form,
        radio:this.radio,
        basePrice: this.basePrice
      };


      // this.actSavePackage(this.data)
      // .then(() => {

      // });

      this.setLocal('coaching-center-onboard', formData);
      this.$emit("menu", this.$route.name);
      this.$router.push({ name: 'coachingCenterContract'});
    },

    clearAll(){
      this.$emit("clear-all", "");
    }
  },

  created() {
    
    this.actPackageBasePrice().then(({items}) => {
      //   this.packageBasePrice = {
      //       "package_type" : "CUSTOMIZE",
      //       "question_value" : 3.50,
      //       "practice_test_value" : 3.50,
      //       "mock_test_value" : 3.50,
      //       "quiz_value" : 3.50,
      //       "webinars_value" : 3.50,
      //       "online_class_value" : 3.50,
      //       "appointment_value" : 3.50,
      //       "active" : true
      // }
      if(items.length>0){
          this.packageBasePrice = items[0];
      }
        
    });
    
    

    let existData = this.getLocal('coaching-center-onboard');
    
    if (existData && existData.organization.package_type) this.form.package_type = existData.organization.package_type;
    if (existData && existData.organization.payment_type) this.form.payment_type = existData.organization.payment_type;
    if (existData && existData.organization.radio) this.radio = existData.organization.radio;
    if (existData && existData.organization.basePrice) this.basePrice = existData.organization.basePrice;
    this.actPackages('?type=PACKAGE').then((items) => this.packages = items);
  },

  validations: {
    form: { 
        package_type: {required}
    }
  }
}
</script>
