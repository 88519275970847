<template>
  <div style="">
    <div class="md-layout">
      <div class="md-layout-item md-size-100">
          <md-checkbox  v-model="form.portalAdminSame" @change="isPortalAdminSame"> 
            <label class="font-bold block text-sm">Select if Portal admin & owner are same</label>
          </md-checkbox>
      </div>
      <div class="md-layout-item md-size-100">
        <InputFieldComponent
            label="Name of the Portal Admin"
            :requiredLabel="true"
            label-class="text-sm"
            v-model.trim="$v.form.portalAdmin.name.$model"
            :message="!$v.form.portalAdmin.name.required && $v.form.portalAdmin.name.$dirty ? 'Field is required' : null"
        />
      </div>
      <div class="md-layout-item md-size-50 mt-5">
        <InputFieldComponent
            label="Email Address"
            :requiredLabel="true"
            label-class="text-sm"
            v-model.trim="$v.form.portalAdmin.email.$model"
            :message="!$v.form.portalAdmin.email.required && $v.form.portalAdmin.email.$dirty ? 'Field is required' : null"
        />
      </div>
      <div class="md-layout-item md-size-50 mt-5">
        <InputFieldComponent
            label="Contact Number"
            :requiredLabel="true"
            label-class="text-sm"
            v-model.trim="$v.form.portalAdmin.user_profile.contact_number.$model"
            :message="!$v.form.portalAdmin.user_profile.contact_number.required && $v.form.portalAdmin.user_profile.contact_number.$dirty ? 'Field is required' : null"
        />
      </div>
      <!-- <div class="md-layout-item md-size-75 mt-5">
        <InputFieldComponent
            label="Designation"
            :requiredLabel="true"
            label-class="text-sm"
            v-model.trim="$v.form.portalAdmin.user_profile.designation.$model"
            :message="!$v.form.portalAdmin.user_profile.designation.required && $v.form.portalAdmin.user_profile.designation.$dirty ? 'Field is required' : null"
        />
      </div> -->
      <div class="md-layout-item md-size-100 mt-5">
        <InputFieldComponent
            label="Full Address"
            :requiredLabel="true"
            label-class="text-sm"
            v-model.trim="$v.form.portalAdmin.user_profile.address_1.$model"
            :message="!$v.form.portalAdmin.user_profile.address_1.required && $v.form.portalAdmin.user_profile.address_1.$dirty ? 'Field is required' : null"
        />
      </div>
      <div class="md-layout-item md-size-100 mt-4">
        <InputFieldComponent
            :requiredLabel="true"
            label-class="text-sm"
            v-model.trim="form.portalAdmin.address_2"
            
        />
      </div>

      <div v-if="!orderCountries" class="md-layout-item md-size-50  mt-5">
        <SelectComponent
            :items="postalCodes"
            label="Postal Code"
            class="w-full mb-2"
            return-type="object"
            @getObject="setPostalCode"
        />
      </div>
      <div v-if="orderCountries" class="md-layout-item md-size-50 mt-5">
        <InputFieldComponent
            :requiredLabel="true"
            label="Postal code"
            label-class="text-sm"
            v-model.trim="$v.form.portalAdmin.user_profile.postal_code_id.$model"
            :message="!$v.form.portalAdmin.user_profile.postal_code_id.required && $v.form.portalAdmin.user_profile.postal_code_id.$dirty ? 'Field is required' : null"
            />
      </div>
    </div>
    <div class="md-layout mt-5">
      <div class="md-layout-item md-size-50 ">
        <InputFieldComponent
            :requiredLabel="true"
            label="State"
            label-class="text-sm"
            v-model.trim="$v.form.portalAdmin.user_profile.state.$model"
            :message="!$v.form.portalAdmin.user_profile.state.required && $v.form.portalAdmin.user_profile.state.$dirty ? 'Field is required' : null"
            />
      </div>
      <div class="md-layout-item md-size-50">
   
        <InputFieldComponent
            :requiredLabel="true"
            label="City"
            label-class="text-sm"
            v-model.trim="$v.form.portalAdmin.user_profile.city.$model"
            :message="!$v.form.portalAdmin.user_profile.city.required && $v.form.portalAdmin.user_profile.city.$dirty ? 'Field is required' : null"
            />
      </div>

      <div class="md-layout-item md-size-100 mt-5">
          <SelectComponent
            :items="countries"
            label="Country"
            :requiredLabel="true"
            class="w-full mb-2"
            v-model="$v.form.portalAdmin.user_profile.country_id.$model"
            :message="!$v.form.portalAdmin.user_profile.country_id.required && $v.form.portalAdmin.user_profile.country_id.$dirty ? 'Field is required' : null"
            @input="getCountryInputChange"
            @getObject="getCountryId"
        />
      </div>
      <div v-if="orderCountries" class="md-layout-item md-size-50 mt-5">
        <SelectComponent
            :items="otherCountries"
            label="Your Country"
            class="w-full mb-2"
            @input="getOtherCountryInputChange"
            v-model.trim="form.portalAdmin.user_profile.your_country_id"
            />
      </div>

    </div>

    <div class="flex justify-end mt-4">
      <md-button class="ml-auto text-uppercase" @click="clearAll">Clear all</md-button>
      <Button
          :loading="loading"
          class="bg-victoria rounded text-white mr-0"
          label="Next"
          type="button"
          @click="submit"
      >
      </Button>
    </div>
  </div>
</template>

<script>

import {
  Button,
  InputFieldComponent,
  SelectComponent
} from "@/components"
import { required } from "vuelidate/lib/validators";
import { email } from "vuelidate/lib/validators";
import {mapActions} from 'vuex';
export default {
  name: "BasicInformation",

  components: {
    Button,
    InputFieldComponent,
    SelectComponent
  },

  data() {
    return {
      
      loading: false,
      orderCountries: false,
      countries: [],
      postalCodes: [],
      otherCountries: [],
      form: {
        portalAdminSame: false,
        portalAdmin: {
          active: 0,
          name: "",
          email: "",
          type: "PORTAL_ADMIN",
          password: 123456,
          password_confirmation: 123456,
          user_profile:{
            contact_number: "",
            designation: "",
            address_1: "",
            address_2: '',
            full_address:'',
            postal_code_id:'',
            country_id: "",
            state: '',
            city: '', 
            country: '',
            your_country: '',
            your_country_id: '',
            postal_code: '',
          }
        },
      }
    }
  },

  methods: {

    ...mapActions({
      actCountryList: "registration/actCountryList",
      actPostalCodesByCountry: "registration/actPostalCodesByCountry",
    }),
    isPortalAdminSame(value){
       
      if(value){
          let existData = this.getLocal('coaching-center-onboard');
          this.form.portalAdmin = existData.user;
      }else{
          this.form.portalAdmin =  {
            active: 0,
            name: "",
            email: "",
            type: "",
            user_profile:{
              contact_number: "",
              designation: "",
              address_1: "",
              address_2: '',
              full_address:'',
              postal_code_id:'',
              country_id: "",
              state: '',
              city: '', 
              country: '',
              your_country: '',
              your_country_id: '',
              postal_code: '',
            }
          }
      }
    },
    setPostalCode(postalCode) {
      this.form.portalAdmin.user_profile.postal_code_id = postalCode.id;
      this.form.postal_code = postalCode.name;
      this.form.portalAdmin.user_profile.state = postalCode.state;
      this.form.portalAdmin.user_profile.city = postalCode.city;
    },

    getCountryName() {
      let country =  this.countries.filter(country => {
        if(country.id === this.form.user_profile.portalAdmin.country_id) return country;
      });
      if(typeof country !== "undefined" && country) return country[0];
    },
    getCountryId(countryId) {
      this.getPostalCodes(countryId);
    },

    getPostalCodes(countryId) {

      this.actPostalCodesByCountry(countryId)
          .then(({items}) => {
            this.postalCodes = items;
            var postalCode = this.postalCodes.slice(0, 1).shift();
            if(typeof postalCode !== "undefined" && postalCode){
              this.form.portalAdmin.user_profile.postal_code_id = postalCode.id;
              this.form.postal_code = postalCode.name;
              this.form.portalAdmin.user_profile.state = postalCode.state;
              this.form.portalAdmin.user_profile.city = postalCode.city;
            }else{
              this.form.portalAdmin.user_profile.postal_code_id = "";
              this.form.postal_code = "";
              this.form.portalAdmin.user_profile.state = "";
              this.form.portalAdmin.user_profile.city = "";
            }
          });
    },

    getCountryInputChange(countryId) {
     
      if (countryId == 'others') {
        this.form.country = countryId;
        this.form.portalAdmin.user_profile.country_id = countryId;
        this.form.portalAdmin.user_profile.city = '';
        this.form.portalAdmin.user_profile.state = '';
        this.form.portalAdmin.user_profile.postal_code_id = '';
        this.orderCountries = true;

        //load first country details
        let otherCountry = this.otherCountries.slice(0, 1).shift();
        if(typeof otherCountry !== "undefined" && otherCountry) {
          this.form.portalAdmin.user_profile.your_country = otherCountry.name;
          this.form.portalAdmin.user_profile.country_id = otherCountry.id;
          this.getPostalCodes(otherCountry.id);
        }
      } else {
        this.getPostalCodes(countryId);
        this.orderCountries = false;
        let countryDerails = this.getCountryName();
        this.form.portalAdmin.user_profile.country_id = countryDerails.id;
        this.form.country = countryDerails.name;
      }
    },

    getOtherCountryName() {
      let otherCountry =  this.otherCountries.filter(otherCountry => {
        if(otherCountry.id === this.form.portalAdmin.user_profile.your_country) return otherCountry;
      });
      if(typeof otherCountry !== "undefined" && otherCountry) return otherCountry[0];
    },
    
    getOtherCountryInputChange(otherCountryId){
      this.form.portalAdmin.user_profile.your_country = otherCountryId;
      this.getPostalCodes(otherCountryId);
      let otherCountryDerails = this.getOtherCountryName();
      this.form.portalAdmin.user_profile.your_country = otherCountryDerails.name;
    },
 
    submit() {

      this.$v.$touch();
      if(this.$v.$invalid) return;
      
      let formData = this.getLocal('coaching-center-onboard');
      formData = {
        ...formData,
        ...this.form
      };

      this.setLocal('coaching-center-onboard', formData);
      this.$emit("menu", this.$route.name);
      this.$router.push({ name: 'coachingCenterReview'});
    },

    clearAll(){
      this.$emit("clear-all", "");
    }
  },

  created() {
    this.actCountryList().then(({main, others}) => {
      this.countries = main;
      this.otherCountries = others;
      this.countries.push({
        name: 'OTHER',
        id: 'others'
      })
      //load first country details
      let firstCountry = this.countries.slice(0, 1).shift();
      if(typeof firstCountry !== "undefined" && firstCountry){
        this.form.country = firstCountry.name;
        this.form.portalAdmin.user_profile.country_id = firstCountry.id;
        this.getPostalCodes(firstCountry.id);
      }
      let existData = this.getLocal('coaching-center-onboard');

      if (existData && existData.portalAdmin && existData.portalAdmin) this.form.portalAdmin = existData.portalAdmin;
      // if (existData && existData.portalAdmin && existData.portalAdmin.email) this.form.portalAdmin.email = existData.portalAdmin.email;
      // if (existData && existData.portalAdmin && existData.portalAdmin.contact_number) this.form.portalAdmin.contact_number = existData.portalAdmin.contact_number;
      // if (existData && existData.portalAdmin && existData.portalAdmin.website) this.form.portalAdmin.website = existData.portalAdmin.website;
      // if (existData && existData.portalAdmin && existData.portalAdmin.address_1) this.form.portalAdmin.address_1 = existData.portalAdmin.address_1;
      // if (existData && existData.portalAdmin && existData.portalAdmin.country_id) this.form.portalAdmin.country_id = existData.portalAdmin.country_id;
      // if (existData && existData.portalAdmin && existData.portalAdmin.state) this.form.portalAdmin.state = existData.portalAdmin.state;
      // if (existData && existData.portalAdmin && existData.portalAdmin.city) this.form.portalAdmin.city = existData.portalAdmin.city;
      // if (existData && existData.portalAdmin && existData.portalAdmin.postal_code_id) this.form.portalAdmin.postal_code_id = existData.portalAdmin.postal_code_id;

  
    });

  },
  validations: {
    form: {
      portalAdmin: {
          name: {required},
          email:{required, email},
          user_profile: {
            contact_number:{required},
            address_1: {required},
            postal_code_id:{required},
            country_id: {required},
            state: {required},
            city: {required}, 
          }
        },
    }
  }

}
</script>
