<template>
  <div style="">
    <div class="md-layout">
      <div class="md-layout-item md-size-50">
        <label class="text-uppercase font-semibold font-bold block mt-6">Do you want Landing page*</label>
        <RadioButtonComponent         
         label-class="font-bold"
         :label-outline="false"
         :block="true"
         align="left"
         :items="[{id:'yes', label: 'Yes'}, {id:'no', label: 'No'}]"
         v-model.trim="$v.form.want_landing_page_value.$model"
         :message="!$v.form.want_landing_page_value.required && $v.form.want_landing_page_value.$dirty ? 'Field is required' : null"
         @input="updateWantLandingPage"
                
        />
        
        <InputFieldComponent v-if="form.want_landing_page_value=='yes'"
            label="Sub-Domain Name"
     
            label-class="text-sm"
            v-model="form.landing_page_url"
        />

        <label v-if="form.want_landing_page_value=='yes'" class="font-bold block mt-6 mb-6">For Example: <span class="text-victoria">www.sub.example.com</span> </label>
      </div>
    </div>

    <div class="flex justify-end mt-4">
      <md-button class="ml-auto text-uppercase" @click="clearAll">Clear all</md-button>
      <Button
          :loading="loading"
          class="bg-victoria rounded text-white mr-0"
          label="Next"
          type="button"
          @click="submit"
      >
      </Button>
    </div>
  </div>
</template>

<script>

import {
  Button,
  InputFieldComponent,
  RadioButtonComponent
} from "@/components"
import { required } from "vuelidate/lib/validators";
export default {
  name: "BasicInformation",

  components: {
    Button,
    InputFieldComponent,
    RadioButtonComponent
  },

  data() {
    return {
      loading: false,
      form: {
        want_landing_page_value: 'no',
        want_landing_page: false,
        landing_page_url: '',
        is_portal_fee_paid: true
      }
    }
  },

  methods: {
    updateWantLandingPage(value) {
   
      if(value=='yes'){
        this.form.want_landing_page = true;
      }else{
        this.form.want_landing_page = false;
        this.form.landing_page_url = '';
      }
    },
    submit() {

      this.$v.$touch();
      if(this.$v.$invalid) return;

      let formData = this.getLocal('coaching-center-onboard');
      formData.organization = {
        ...formData.organization,
        ...this.form
      };

      this.setLocal('coaching-center-onboard', formData);
      this.$emit("menu", this.$route.name);
      this.$router.push({ name: 'coachingCenterPortalAdmin'});
    },

    clearAll(){
      this.$emit("clear-all", "");
    }
  },

  created() {
    let existData = this.getLocal('coaching-center-onboard');
    
    if (existData && existData.organization.want_landing_page_value) this.form = existData.organization;
  },

  validations: {
    form: { 
      want_landing_page_value : {required},
    }
  }
}
</script>
