<template>
  <div style="">
    <div class="md-layout">
      <div class="md-layout-item md-size-100">
        <div class="md-layout mt-6">
      <div class="md-layout-item">
        <div class="outline-gray-400 p-4 rounded" style="max-height: 450px; overflow-y: auto">
          <h2 class="mb-2 text-base text-gray-700 text-uppercase">Contract *</h2>
          <div>
            <p class="text-base line-height-30">Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique
              magnam, omnis autem excepturi nesciunt id!</p>
            <p class="text-base line-height-30">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iusto a sit
              reiciendis impedit modi tempore suscipit, perferendis quas adipisci laborum eveniet fugit tenetur
              molestias id? Consequuntur fugit quod qui saepe veniam suscipit neque laudantium consequatur maiores.
              Sint, reiciendis culpa tempore odio nesciunt beatae atque eveniet perferendis vero soluta? Perspiciatis
              fugiat necessitatibus magni, aliquam numquam dicta aperiam ipsum minima similique harum nisi accusamus
              repellendus asperiores. Sit culpa, quos non aperiam esse quas accusantium a inventore dolore pariatur
              excepturi reiciendis eaque laborum earum qui architecto ad! Vel, aliquid mollitia quia sed reiciendis illo
              aut quos earum autem suscipit sit vitae ullam provident hic consectetur. Quas recusandae in suscipit nulla
              nemo rerum vitae inventore accusantium itaque possimus nostrum magni ullam, tempora numquam. Eos excepturi
              similique libero culpa necessitatibus mollitia possimus repudiandae quae! Debitis quaerat nam quasi
              voluptas accusamus nemo odit, ullam eum animi, facilis corporis iure adipisci veniam unde hic a placeat
              iusto, dolorem harum sequi delectus provident ipsa nisi. Possimus quidem vitae rerum incidunt in fugit
              neque aspernatur. Id optio voluptas eum laboriosam impedit, dolor sit vel magnam ad ea amet enim, cum
              dolorem illum aspernatur? Debitis doloremque reiciendis quaerat! Vel necessitatibus ex quas commodi
              perspiciatis assumenda qui quod dicta est tempore deleniti praesentium, et distinctio veniam quisquam
              nihil asperiores eius dolores? Dignissimos nesciunt aut eveniet. Magnam aspernatur debitis laborum
              reprehenderit officia iusto distinctio quibusdam rem aliquid non, perspiciatis nihil porro similique
              facilis nulla voluptatibus perferendis, recusandae veritatis quos nemo impedit maxime explicabo.
              Voluptatibus provident sapiente reiciendis itaque ducimus possimus atque. Velit.</p>
            <p class="text-base line-height-30">Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique
              magnam, omnis autem excepturi nesciunt id!</p>
            <div class="mt-4">
              <md-radio v-model="acceptContract" value="true" class="md-primary">Yes, I agree with the terms & condition
              </md-radio>
            </div>
          </div>
        </div>
      </div>
    </div>
      </div>
    </div>

    <div class="flex justify-end mt-4">
      <md-button class="ml-auto text-uppercase" @click="clearAll">Clear all</md-button>
      <Button
          :loading="loading"
          class="bg-victoria rounded text-white mr-0"
          label="Next"
          type="button"
           :disabled="!acceptContract"
          @click="submit"
      >
      </Button>
    </div>
  </div>
</template>

<script>

import {
  Button,
} from "@/components"
export default {
  name: "BasicInformation",

  components: {
    Button,
  },

  data() {
    return {
      loading: false,
      acceptContract: false
    }
  },

  methods: {
 
    submit() {

      let formData = this.getLocal('coaching-center-onboard');
      formData = {
        ...formData,
        ...this.form
      };

      this.setLocal('coaching-center-onboard', formData);
      this.$emit("menu", this.$route.name);
      this.$router.push({ name: 'coachingCenterPortalAccess'});
    },

    clearAll(){
      this.$emit("clear-all", "");
    }
  },

}
</script>
